<template>
  <v-container fluid>
    <v-card flat color="transparent" class="pa-sm-4">
      <v-card-title class="primary--text">
        New Item
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="mt-6">
        <v-form ref="newForm" @submit.prevent>
          <v-row>
            <v-col cols="12" md="6" lg="4" class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-autocomplete
                v-else
                flat
                v-model="item.category_id"
                :items="categories"
                item-text="name"
                item-value="id"
                label="Select Main Category"
                outlined
                :rules="[rules.required]"
                @change="changeCategory"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-autocomplete
                v-else
                flat
                v-model="item.sub_category_id"
                :items="subCategories"
                item-text="name"
                item-value="id"
                label="Select Sub Category"
                outlined
                @change="changeSubCategory"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-autocomplete
                v-else
                flat
                v-model="item.sub_sub_category_id"
                :items="subSubCategories"
                item-text="name"
                item-value="id"
                label="Select Sub Sub Category"
                outlined
                @change="changeSubCategory"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                flat
                outlined
                label="Item Name"
                v-model="item.name"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-textarea
                v-else
                flat
                outlined
                label="Description"
                v-model="item.description"
                :rules="[rules.required]"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                type="number"
                flat
                outlined
                label="Price LKR"
                v-model="item.price_lkr"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                type="number"
                flat
                outlined
                label="Price USD"
                v-model="item.price_usd"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                flat
                outlined
                label="Per Unit"
                v-model="item.per_unit"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-autocomplete
                v-else
                flat
                v-model="item.price_type"
                :items="prizeTypes"
                label="Price Type"
                outlined
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                type="number"
                flat
                outlined
                label="Discount %"
                v-model="item.discount_percent"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-dialog v-else v-model="menu" persistent width="300px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    v-model="item.hot_deal_end_at"
                    flat
                    outlined
                    label="Hot Deal End at"
                    readonly
                    clearable
                  ></v-text-field>
                </template>

                <v-card class="text-center">
                  <v-date-picker
                    color="primary"
                    v-model="date"
                    @input="formattedDateTime"
                  ></v-date-picker>
                  <v-time-picker
                    color="primary"
                    v-model="time"
                    @input="formattedDateTime"
                  ></v-time-picker>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="secondary" @click="menu = false"
                      >Cancel</v-btn
                    >
                    <v-btn depressed color="primary" @click="menu = false"
                      >OK</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="6" lg="4" class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-sheet v-else class="d-flex">
                <v-checkbox
                  label="Featured"
                  v-model="item.is_featured"
                ></v-checkbox>
                <!-- <v-checkbox
                  label="Sold"
                  v-model="item.is_sold"
                ></v-checkbox> -->
              </v-sheet>
            </v-col>
          </v-row>

          <v-sheet color="transparent" class="d-flex my-10 align-center">
            Images
            <v-divider class="ms-2"></v-divider>
          </v-sheet>
          <v-row>
            <v-col cols="12">
              <v-file-input
                ref="imgInput"
                flat
                outlined
                accept="image/*"
                label="Select an image"
                @change="onFileChange"
                class="d-none"
              >
              </v-file-input>
              <v-btn @click="uploadImg" depressed>
                <v-icon color="primary" class="me-2"
                  >mdi-camera-plus-outline</v-icon
                >
                Upload Item Images
              </v-btn>
            </v-col>
            <v-sheet
              color="transparent"
              class="text-center d-inline text-caption error--text mx-6"
              v-if="images.length == 0"
            >
              Upload at least one image.
            </v-sheet>

            <v-col
              cols="6"
              md="4"
              lg="3"
              v-for="(image, i) in images"
              :key="'i' + i"
            >
              <v-img
                :src="image.preview"
                aspect-ratio="1"
                max-width="100%"
                class="d-flex pa-2 text-end align-end"
              >
                <v-btn
                  fab
                  depressed
                  small
                  color="error"
                  @click="removeImage(i)"
                >
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-img>
              <v-sheet
                color="transparent"
                class="text-center text-caption error--text mt-1"
                v-if="image.file.size >= 500 * 1024"
              >
                ({{ image.file.size / 500 }} KB) File size must be less than 500
                KB.</v-sheet
              >
            </v-col>
          </v-row>
          <v-sheet color="transparent" class="d-flex my-10 align-center">
            Properties
            <v-divider class="ms-2"></v-divider>
          </v-sheet>

          <v-btn
            depressed
            v-if="item.item_properties.length == 0"
            @click="addItemProperty"
          >
            <v-icon color="primary" class="me-2">
              mdi-package-variant-closed-plus
            </v-icon>
            Add Property
          </v-btn>

          <v-row v-for="(property, i) in item.item_properties" :key="'p' + i">
            <v-col class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                flat
                outlined
                label="Key"
                v-model="property.key"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                flat
                outlined
                label="Value"
                v-model="property.value"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col class="my-0 py-0 d-flex align-center">
              <v-btn icon depressed @click="removeItemProperty(i)">
                <v-icon color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
              <v-btn
                icon
                depressed
                v-if="i == item.item_properties.length - 1"
                @click="addItemProperty"
              >
                <v-icon color="primary">mdi-package-variant-closed-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-sheet color="transparent" class="d-flex my-10 align-center">
            Features
            <v-divider class="ms-2"></v-divider>
          </v-sheet>

          <v-btn
            depressed
            v-if="item.item_features.length == 0"
            @click="addItemFeature"
          >
            <v-icon color="primary" class="me-2"
              >mdi-package-variant-closed-plus</v-icon
            >
            Add Feature
          </v-btn>

          <v-row v-for="(feature, i) in item.item_features" :key="'f' + i">
            <v-col class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                flat
                outlined
                label="Name"
                v-model="feature.name"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                flat
                outlined
                label="MDI Icon"
                v-model="feature.mdi_icon"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col class="my-0 py-0 d-flex align-center">
              <v-btn icon depressed @click="removeItemFeature(i)">
                <v-icon color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
              <v-btn
                icon
                depressed
                v-if="i == item.item_features.length - 1"
                @click="addItemFeature"
              >
                <v-icon color="primary">mdi-package-variant-closed-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-sheet color="transparent" class="d-flex my-10 align-center">
            Embeds
            <v-divider class="ms-2"></v-divider>
          </v-sheet>

          <v-btn
            depressed
            v-if="item.item_embeds.length == 0"
            @click="addItemEmbed"
          >
            <v-icon color="primary" class="me-2"
              >mdi-package-variant-closed-plus</v-icon
            >
            Add Embed
          </v-btn>

          <v-row v-for="(embed, i) in item.item_embeds" :key="'e' + i">
            <v-col class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                flat
                outlined
                label="Title"
                v-model="embed.title"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col class="my-0 py-0">
              <v-skeleton-loader
                class="my-6"
                type="image"
                height="50"
                v-if="dataLoading"
              ></v-skeleton-loader>
              <v-text-field
                v-else
                flat
                outlined
                label="HTML"
                v-model="embed.html"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col class="my-0 py-0 d-flex align-center">
              <v-btn icon depressed @click="removeItemEmbed(i)">
                <v-icon color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
              <v-btn
                icon
                depressed
                v-if="i == item.item_embeds.length - 1"
                @click="addItemEmbed"
              >
                <v-icon color="primary">mdi-package-variant-closed-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-title v-if="!dataLoading">
        <v-spacer></v-spacer>
        <v-btn color="secondary" depressed @click="goBack" text class="mx-3">
          cancel
        </v-btn>
        <v-btn color="primary" depressed @click="createItem" :loading="loading">
          Create
        </v-btn>
      </v-card-title>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { addItem } from "@/services/itemsService";
import { uploadDocument } from "@/services/uploadDocumentsService";
import { getCategoriesData } from "@/services/preloadDataService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    loading: false,
    dataLoading: false,
    rules: {
      required: (value) => !!value || "Required.",
      fileSize: (value) => {
        if (!value) return true;
        return (
          value.size <= 500 * 1024 || "File size must be less than 500 KB."
        );
      },
    },
    menu: false,
    date: null,
    time: null,
    item: {
      name: "",
      description: "",
      category_id: null,
      sub_category_id: null,
      sub_sub_category_id: null,
      price_lkr: 0,
      price_usd: 0,
      price_type: "",
      per_unit: "",
      discount_percent: 0,
      is_featured: false,
      is_enabled: true,
      is_sold: false,
      hot_deal_end_at: "",
      item_properties: [],
      item_features: [],
      item_images: [],
      item_embeds: [],
    },
    prizeTypes: ["Fixed", "Negotiable", "Onwards"],
    images: [],
    categories: [],
    subCategories: [],
    subSubCategories: [],
  }),
  async created() {
    this.getData();
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "PageItemList",
        query: {
          cid: this.item.category_id,
          scid: this.item.sub_category_id,
          sscid: this.item.sub_sub_category_id,
        },
      });
    },
    async getData() {
      this.dataLoading = true;
      this.categories = await getCategoriesData();
      this.dataLoading = false;
    },
    async createItem() {
      if (
        this.$refs.newForm.validate() &&
        this.images.length > 0 &&
        !this.images.some((obj) => obj.file.size > 500 * 1024)
      ) {
        this.loading = true;

        if (this.images.length > 0) {
          const uploadPromises = this.images.map(async (element) => {
            const formData = new FormData();
            formData.append("file", element.file);
            const uploadDocumentReturn = await uploadDocument(
              "items",
              formData
            );
            this.item.item_images.push({
              image_url: uploadDocumentReturn.document_url,
            });
          });

          await Promise.all(uploadPromises);
        }

        let addItemReturn = await addItem(this.item);
        this.loading = false;
        if (addItemReturn == "success") {
          this.goBack();
        }
      }
    },
    onFileChange(file) {
      if (file) {
        this.images.push({ file: file, preview: URL.createObjectURL(file) });
      }
    },
    changeCategory() {
      this.subCategories = this.categories.find(
        (element) => element.id == this.item.category_id
      ).sub_categories;
    },
    changeSubCategory() {
      this.subSubCategories = this.subCategories.find(
        (element) => element.id == this.item.sub_category_id
      ).sub_sub_categories;
    },
    formattedDateTime() {
      if (this.date && this.time) {
        this.item.hot_deal_end_at = new Date(
          `${this.date} ${this.time}`
        ).toISOString();
      }
    },
    addItemProperty() {
      this.item.item_properties.push({
        key: "",
        value: "",
      });
    },
    removeItemProperty(index) {
      this.item.item_properties.splice(index, 1);
    },
    addItemFeature() {
      this.item.item_features.push({
        name: "",
        mdi_icon: "",
      });
    },
    removeItemFeature(index) {
      this.item.item_features.splice(index, 1);
    },
    addItemEmbed() {
      this.item.item_embeds.push({
        title: "",
        html: "",
      });
    },
    removeItemEmbed(index) {
      this.item.item_embeds.splice(index, 1);
    },
    uploadImg() {
      this.$refs.imgInput.$el.querySelector('input[type="file"]').click();
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
  },
};
</script>